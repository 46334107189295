import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import router from "@/router";

const sidebarTypes = {
  details: {
    route: "details",
    changeRouteOnOpen: true,
    forbiddenRoutes: [],
  },
  info: {
    route: "info",
    changeRouteOnOpen: true,
    forbiddenRoutes: [],
  },
  html: {
    route: false,
    changeRouteOnOpen: false,
    forbiddenRoutes: [],
  },
  preview: {
    route: "preview",
    changeRouteOnOpen: true,
    forbiddenRoutes: [],
  },
  group: {
    route: "group",
    changeRouteOnOpen: true,
    forbiddenRoutes: [],
  },
};

export default {
  state: () => ({
    sidebars: [],
    sidebarModal: null,
  }),
  getters: {
    sidebarTypes() {
      return sidebarTypes;
    },

    allSidebars(state) {
      return state.sidebars;
    },
    visibleSidebars(state) {
      const last = state.sidebars.slice(-2);
      return last;
    },
    sidebarCount(state) {
      return state.sidebars.length;
    },
    lastSidebar(state) {
      return state.sidebars[state.sidebars.length - 1] || null;
    },
    sidebarModal(state) {
      return state.sidebarModal;
    },

    sidebarById: (state) => (id) => {
      return state.sidebars.find((s) => s.id === id);
    },
  },
  mutations: {
    OPEN_SIDEBAR(state, sidebar) {
      state.sidebars.push(sidebar);
      window.eventBus.$emit("sidebar:open", sidebar);
    },
    CLOSE_SIDEBAR(state, index) {
      const sidebar = state.sidebars[index]
      state.sidebars.splice(index, 1);
      window.eventBus.$emit("sidebar:close", sidebar);
    },
    CLOSE_ALL_SIDEBARS(state) {
      state.sidebars = [];
    },
    UPDATE_SIDEBAR(state, { sidebar, index }) {
      const oldSidebar = state.sidebars[index]
      Vue.set(state.sidebars, index, sidebar);
      window.eventBus.$emit("sidebar:update", sidebar, oldSidebar);
    },

    OPEN_SIDEBAR_MODAL(state, modal) {
      state.sidebarModal = modal;
    },
    CLOSE_SIDEBAR_MODAL(state) {
      state.sidebarModal = null;
    },

    SET_SIDEBAR_OFFSET(state, { index, offset }) {
      if (index >= 0 && state.sidebars[index]) {
        const sidebar = state.sidebars[index];
        Vue.set(state.sidebars, index, {
          ...state.sidebars[index],
          offset,
        });
        if (sidebar.id == state.sidebarModal?.sidebar_id) {
          state.sidebarModal.offset = offset;
        }
      }
    },
  },
  actions: {
    OPEN_SIDEBAR({ commit, state, getters, dispatch }, sidebarConfig) {
      // Проверяем, разрешен ли сайдбар на текущем маршруте
      const currentRoute = router.currentRoute;
      if (!sidebarConfig.type && sidebarConfig.route) {
        sidebarConfig.type = Object.keys(sidebarTypes).find(
          (key) => sidebarTypes[key].route === sidebarConfig.route
        );
      }
      sidebarConfig.route = sidebarTypes[sidebarConfig.type].route;

      if (!isSidebarAllowedOnRoute(sidebarConfig.type, currentRoute.name)) {
        console.warn(
          `Сайдбар типа ${sidebarConfig.type} не разрешен на маршруте ${currentRoute.name}`
        );
        return false;
      }

      const defaultSidebarConfig = {
        changeRouteOnOpen: true,
        isSingleton: true,
        context: "",
        subcontext: "",
        size: 1,
        persist: false,
        payload: {},
        offset: 0,
      };

      sidebarConfig = { ...defaultSidebarConfig, ...sidebarConfig };
      // Генерируем уникальный ID, если не указан
      const id = sidebarConfig.id || `sidebar-${Date.now()}`;
      const sidebar = {
        ...sidebarConfig,
        id,
        persist: false,
        uuid: `sidebar-uuid-${Date.now()}`,
      };
      let existingIndex = -1;
      if (sidebar.isSingleton) {
        existingIndex = state.sidebars.findIndex(
          (s) => s.type === sidebar.type && s.isSingleton
        );
      }

      if (
        sidebarTypes[sidebar.type].changeRouteOnOpen !== false &&
        sidebar.changeRouteOnOpen !== false &&
        (!sidebar.isSingleton ||
          (sidebar.isSingleton && existingIndex === -1) ||
          (sidebar.isSingleton && existingIndex === state.sidebars.length - 1))
      ) {
        updateUrlWithSidebar(sidebar);
      }

      if (sidebar.isSingleton && existingIndex !== -1) {
        if (existingIndex !== 0) {
          commit("SET_SIDEBAR_OFFSET", {
            index: existingIndex - 1,
            offset: sidebar.size,
          });
        }
        sidebar.id = state.sidebars[existingIndex].id;
        return dispatch("UPDATE_SIDEBAR", sidebar);
      }
      if (state.sidebars.length > 0) {
        commit("SET_SIDEBAR_OFFSET", {
          index: state.sidebars.length - 1,
          offset: sidebar.size,
        });
      }
      const lastSidebarId = getters.lastSidebar?.id;
      commit("OPEN_SIDEBAR", sidebar);
      if (lastSidebarId != getters.lastSidebar.id) {
        updateUrlWithSidebar(getters.lastSidebar);
      }
      return sidebar;
    },
    CLOSE_SIDEBAR({ commit, getters, state, dispatch }, id) {
      const lastSidebarId = getters.lastSidebar?.id;
      dispatch("CLOSE_SIDEBAR_HANDLER", id);
      if (lastSidebarId != getters.lastSidebar?.id) {
        updateUrlWithSidebar(getters.lastSidebar);
      }
    },
    CLOSE_SIDEBAR_HANDLER({ commit, getters, state }, id) {
      const index = state.sidebars.findIndex((s) => s.id === id);
      if (index === -1) {
        return;
      }
      if (state.sidebars.length - 1 === index) {
        commit("SET_SIDEBAR_OFFSET", {
          index: state.sidebars.length - 2,
          offset: 0,
        });
      } else if (index < state.sidebars.length - 1 && index > 0) {
        commit("SET_SIDEBAR_OFFSET", {
          index: index - 1,
          offset: state.sidebars[index + 1].size,
        });
      }
      commit("CLOSE_SIDEBAR", index);
      if (id == state.sidebarModal?.sidebar_id) {
        commit("CLOSE_SIDEBAR_MODAL");
      }
    },
    CLOSE_ALL_SIDEBARS({ commit }) {
      commit("CLOSE_ALL_SIDEBARS");
      commit("CLOSE_SIDEBAR_MODAL");
      // Обновляем URL
      // updateBodyClassWithSidebar(state.sidebars);
      // updateUrlWithSidebar(null);
    },

    CLOSE_NOT_PERSIST_SIDEBARS({ dispatch, state }) {
      state.sidebars
        .filter((s) => !s.persist)
        .forEach((s, index) => {
          dispatch("CLOSE_SIDEBAR_HANDLER", s.id);
        });
    },

    CLOSE_SIDEBAR_BY_PARAMS({ commit, getters, state, dispatch }, params) {
      state.sidebars
        .filter((s) => {
          return Object.keys(params).every((key) => s[key] == params[key]);
        })
        .forEach((s, index) => {
          dispatch("CLOSE_SIDEBAR", s.id);
        });
    },
    UPDATE_SIDEBAR({ commit, state, getters, dispatch }, updatedSidebar) {
      const index = state.sidebars.findIndex((s) => s.id === updatedSidebar.id);
      if (index === -1) {
        return null;
      }
      const lastSidebarId = getters.lastSidebar?.id;

      const sidebar = state.sidebars[index];
      const sidebarConfig = { ...sidebar, ...updatedSidebar };
      sidebarConfig.offset = sidebar.offset;
      sidebarConfig.uuid = `sidebar-uuid-${Date.now()}`;
      commit("UPDATE_SIDEBAR", { sidebar: sidebarConfig, index: index });
      if (
        state.sidebarModal != null &&
        sidebar.id == state.sidebarModal?.sidebar_id
      ) {
        dispatch("CLOSE_SIDEBAR_MODAL");
      }
      if (lastSidebarId != getters.lastSidebar?.id) {
        updateUrlWithSidebar(getters.lastSidebar);
      }
      return sidebarConfig;
    },

    OPEN_SIDEBAR_MODAL({ commit, state, getters }, modalConfig) {
      if (!modalConfig.sidebar_id) {
        console.warn(
          `Пожалуйста, укажите идентификатор для модального окна сайдбара.`
        );
        return;
      }
      const sidebar = state.sidebars.find(
        (s) => s.id === modalConfig.sidebar_id
      );
      modalConfig.size = sidebar.size;
      modalConfig.offset = sidebar.offset;

      commit("OPEN_SIDEBAR_MODAL", modalConfig);
    },
    CLOSE_SIDEBAR_MODAL({ commit }) {
      commit("CLOSE_SIDEBAR_MODAL");
    },
  },
};

function isSidebarAllowedOnRoute(type, routeName) {
  return !sidebarTypes[type].forbiddenRoutes.includes(routeName);
}

function updateUrlWithSidebar(sidebar) {
  const currentRoute = router.currentRoute;

  let newPath = currentRoute.path.split("/").filter(Boolean);

  if (currentRoute.meta.isSidebar) {
    for (let i = newPath.length - 1; i >= 0 && i >= newPath.length - 3; i--) {
      if (
        Object.values(sidebarTypes).some(
          (sidebar) => sidebar.route === newPath[i]
        )
      ) {
        newPath = newPath.slice(0, i);
        break;
      }
    }
  }

  if (
    sidebar != null &&
    sidebar.type &&
    sidebarTypes[sidebar.type] &&
    sidebarTypes[sidebar.type].route
  ) {
    newPath.push(sidebarTypes[sidebar.type].route);

    if (sidebar.context) {
      newPath.push(sidebar.context);
    }
    if (sidebar.subcontext) {
      newPath.push(sidebar.subcontext);
    }
  }

  const fullPath = "/" + newPath.join("/");

  const matchedRoute = router.match(fullPath);

  if (!matchedRoute.matched.length) {
    console.error("No route matched for path:", fullPath);
    return;
  }
  if (matchedRoute.path === currentRoute.path) {
    return;
  }
  router.push({ path: fullPath, query: currentRoute.query });

  return;
}
